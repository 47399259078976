import { createGlobalStyle } from "styled-components"
// import { normalize } from "styled-normalize"

/* ${normalize} */
export const GlobalStyle = createGlobalStyle`

  /* body {
    font-family: 'Open Sans', sans-serif;
  }

  h1, h2, h3, h4,h5, h6 {
    margin-top: 0;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.05rem;
    line-height: 2rem;
    color: #363636;
  }

  p, li, a {
    line-height: 1.5rem;
    font-size: 1.1rem;
    margin-top: 0;
    color: rgba(0, 0, 0, 0.7)
  }

  a {
    text-decoration: none;
    color: #7cb48f;
  } */
`
