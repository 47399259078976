import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const targets = [
  { anchor: "included", text: "What's Inside" },
  { anchor: "instructor", text: "Instructor" },
  { anchor: "buy", text: "Buy" },
  { anchor: "faq", text: "FAQ" },
]

export default ({ isFixedTop = true }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            login_link
          }
        }
      }
    `
  )

  return (
    <nav
      className={`navbar is-info ${isFixedTop && "is-fixed-top"}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={require("../images/logo.png")} alt="Logo" />
          </Link>

          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={`navbar-menu ${isOpen && "is-active"}`}
        >
          <div className="navbar-start">
            {targets.map(target => (
              // <a
              //   className="navbar-item"
              //   key={target.text}
              //   href={`#${target.anchor}`}
              // >
              //   {target.text}
              // </a>
              <Link
                className="navbar-item"
                key={target.text}
                to={`/#${target.anchor}`}
              >
                {target.text}
              </Link>
            ))}
            <Link to="/blog" className="navbar-item">
              Blog
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-primary" href="/#buy">
                  <strong>Sign up</strong>
                </a>
                <a
                  className="button is-light"
                  href={site.siteMetadata.login_link}
                >
                  Log in
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
