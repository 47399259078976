import React from "react"

export default () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        <b>React Native by Example </b>
        by
        <a href="http://www.handlebarlabs.com"> Handlebar Labs</a>.
      </p>
    </div>
  </footer>
)
